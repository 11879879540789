<template>
  <div class="header">
    <div class="logo"></div>
    <div class="header-btn btn1">
      <span class="count-header-info">{{ $t("count_header1") }}</span
      ><span class="count-text-info"
        >{{ $t("count_text1") }} {{ this.checkCount }}</span
      >
    </div>
    <div class="header-btn btn2">
      <span class="count-header-info">{{ $t("count_header2") }}</span
      ><span class="count-text-info"
        >{{ $t("count_text2") }} {{ this.prizeCount }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  emits: [""],
  props: ["checkCount", "prizeCount"],
};
</script>

<style>
.header {
  z-index: 5;
  text-align: center;
}
.header .logo {
  position: absolute;
  width: 190.12px;
  height: 18px;
  left: calc(50% - 190.12px / 2 + 0.06px);
  top: 64px;

  z-index: 5;
}
.header h1 {
  margin: 0;
  position: absolute;
  width: 525px;
  height: 80px;
  left: calc(50% - 525px / 2 + 0.5px);
  top: calc(50% - 80px / 2 - 209px);

  z-index: 5;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  font-style: italic;
}

.header h1 span,
span.yellow {
  color: #ffce06;
}
.logo {
  line-height: 0;
  background: url(../assets/logo.svg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: cover;
}
.header-btn {
  text-align: center;
  text-transform: uppercase;
  background: #ffce06;
  border-radius: 12px;
  color: #003333;
  font-style: italic;
  display: flex;
  flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}
.count-header-info {
  font-weight: 900;
  font-size: 22px;
line-height: 24px;
}
.count-text-info {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}
.btn1 {
  position: absolute;
  width: 288px;
  height: 72px;
  left: calc(50% - 288px / 2 - 288px);
  top: 36px;
}
.btn2 {
  position: absolute;
  width: 288px;
  height: 72px;
  left: calc(50% - 288px / 2 + 288px);
  top: 36px;
}

@media screen and (max-width: 959px) {


  .header .logo {
width: 169px;
height: 16px;
left: calc(50% - 169px/2 - 0.5px);
top: 24px;
  }
  .btn1 {
    width: 288px;
    height: 56px;
    left: calc(50% - 288px / 2);
    top: 76px;
  }
  .btn2 {
    width: 288px;
    height: 56px;
    left: calc(50% - 288px / 2);
    top: 148px;
  }
  .count-header-info {
font-style: italic;
font-weight: 900;
font-size: 18px;
line-height: 16px;
}
.count-text-info {
font-weight: 800;
font-size: 12px;
line-height: 16px;
}
}
@media screen and (max-width: 599px) {
  .header .logo {
width: 169px;
height: 16px;
left: calc(50% - 169px/2 - 0.5px);
top: 24px;
  }
  .btn1 {
    width: 264px;
    height: 56px;
    left: calc(50% - 264px / 2);
    top: 76px;
  }
  .btn2 {
    width: 264px;
    height: 56px;
    left: calc(50% - 264px / 2);
    top: 144px;
  }
  .count-header-info {
font-weight: 900;
font-size: 16px;
line-height: 14px;
}
.count-text-info {
font-weight: 800;
font-size: 10px;
line-height: 14px;
}
}
@media screen and (max-width: 599px) and (max-height: 552px) {
  .header .logo {
width: 169px;
height: 16px;
left: calc(50% - 169px/2 - 0.5px);
top: 24px;
  }


    .btn1 {
  width: 264px;
height: 56px;
left: calc(50% - 264px/2);
top: 64px;
  }
  .btn2 {
    width: 264px;
height: 56px;
left: calc(50% - 264px/2);
top: 132px;

  }
}
</style>
