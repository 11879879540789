<template>

  <div class="registration-layout registration-layout-form" 
    v-show="currentStepForm == 'two'">
    <h2  id="form-title" v-html="$t('header_site2')" v-show="currentStepForm == 'two'"></h2>
    <div class="form-group">


    <div class="tabs registration-layout-tabs top-tabs">
      <div
        class="tab registration-layout-tabs-tab"
        :class="{ active: currentStep == 'one' }"
        @click="(currentStep = 'one', validation())"
      >
        <i class="icon phone"></i> {{ $t("by_phone") }}
      </div>
      <div
        class="tab registration-layout-tabs-tab"
        :class="{ active: currentStep == 'two' }"
        @click="(currentStep = 'two'), validation()"
      >
        <i class="icon email"></i> {{ $t("by_email") }}
      </div>
    </div>

    <div class="registration-layout-body-row">
      <div class="registration-layout-body-row-cell country-cell">
        <el-select
          v-model="value"
          placeholder="Select"
          @change="changeCountry(value)"
        >
          <template #prefix><div :class="flagIconClass"></div></template>
          <el-option
            v-for="item in arrOfObj1"
            :key="item.name"
            :label="item.name"
            :value="item.name"
            :disabled="item.disabled"
          />
        </el-select>
      </div>
      <div class="registration-layout-body-row-cell currency-cell">
        <el-select v-model="currency" placeholder="Select">
          <el-option
            v-for="item in currencyArr"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </div>
    </div>
    <div class="tab-content registration-layout-body-row">
      <div
        class="item registration-layout-body-row-cell"
        :class="{ active: currentStep == 'one' }"
      >
        <el-input
        type="tel"
          v-model="inputphone"
          placeholder=""
          :class="{ invalid: errors.phone }"
          :maxlength="13"
          @keypress="isNumber($event)"
        >
          <template #prepend>{{ $t("phone") }}</template>
        </el-input>
        <div class="tooltip" v-if="errors.phone">{{ errors.phone }}</div>
      </div>
      <div
        class="item registration-layout-body-row-cell"
        :class="{ active: currentStep == 'two' }"
      >
        <el-input
          v-model="inputemail"
          placeholder=""
          :class="{ invalid: errors.email }"
          
        >
          <template #prepend>{{ $t("email") }}</template>
        </el-input>
        <div class="tooltip" v-if="errors.email">{{ errors.email }}</div>
      </div>
    </div>
  <div class="registration-layout-bonus-title">{{ $t("bonus_title") }}</div>
    <div class="tabs registration-layout-tabs bonus-tab">
      <div
        class="tab registration-layout-tabs-tab"
        :class="{ active: bonus_type == 'sport' }"
        @click="bonus_type = 'sport'"
      >
        <i class="icon sport"> </i>{{ $t("bonus_sport") }}
      </div>
      <div
        class="tab registration-layout-tabs-tab"
        :class="{ active: bonus_type == 'casino' }"
        @click="bonus_type = 'casino'"
      >
        <i class="icon casino"></i> {{ $t("bonus_casino") }}
      </div>
      <div
        class="tab registration-layout-tabs-tab"
        :class="{ active: bonus_type == '' }"
        @click="bonus_type = ''"
      >
        <i class="icon cross"> </i>{{ $t("bonus_decline") }}
      </div>
    </div>

    <div class="add-promocode" @click="triggerPromocode" v-if="!add_promocode">
      + {{ $t("add_promocode") }}
    </div>
    <div
      class="promocodefield registration-layout-body-row"
      v-if="add_promocode"
    >
      <el-input v-model="promocode1" placeholder="">
        <template #prepend>{{ $t("promocode") }}</template>
      </el-input>
    </div>
    </div>
    <div></div>
    <el-button class="reg-btn" @click="recaptcha" data-action="submit" :disabled="!isValid">{{
      $t("registration")
    }}</el-button>
    <div class="registration-layout-body-row has-accout-row">
      <div class="registration-layout-body-row-cell" style="text-align: center">
        <a
          :href="link"
          target="_blank"
          rel="noopener noreferrer"
          class="has-account"
        >
          <i class="icon enter"> </i>{{ $t("has_account") }}</a
        >
      </div>
    </div>
      <div class="registration-layout-body-row form-final-text">
      <p class="licence-info" v-html="$t('final_text')"></p>
    </div>
  </div>
     <div
    class="registration-layout registration-layout_tree"
    v-show="currentStepForm == 'four'"
     >
    <div class="reg-data">
      <div class="reg-data-container reg-data-container-login">
        <div class="reg-data-title">{{ $t("login") }}</div>
        <div class="reg-data-description">{{ responseData.login }}</div>
      </div>
      <div class="reg-data-container reg-data-container-pswd">
        <div class="reg-data-title">{{ $t("password") }}</div>
        <div class="reg-data-description">{{ responseData.password }}</div>
      </div>
      <div class="reg-data-container  reg-data-container-link">
        <div class="reg-data-btn">
              <a class="el-button" target="_blank" :href="responseData.autoLoginUrl" >{{ $t("make_deposit") }}</a>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import axios from "axios";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { ElButton, ElSelect, ElInput, ElOption } from "element-plus";
export default {
  data() {
    return {
       cookie: 0,
      currentStepForm: "two",
      currentStep: "one",
      add_promocode: false,
      isValid: false,
      arrOfObj1: [],
      value: "",
      selectedCountry: "",
      countryObj: {},
      promocode1: "",
      countryCode: window.serverData.visitorData.countryCode,
      currencyArr: ["USD", "EUR"],
      currency: "",
      currencyCode: window.serverData.visitorData.currencyCode,
      promoApiBaseUrl: window.serverData.promoApiBaseUrl,
      sitePath: window.serverData.sitePath,
      link: window.serverData.clickUrl,
      inputphone: "",
      countryCodePhone: "",
      inputemail: "",
      message: "",
      bonus_type: "sport",
      registration_data: {},
      siteKey: "",
      token: "",
      tokenIsLoaded: false,
      result: {},
      flagIconClass: "",
      errors: {
        phone: null,
        email: null,
      },
             responseData: {
        login: null,
        password: null,
        autoLoginUrl: null,
        autoLoginHomepageUrl: null,
      },
    };
  },
  watch: {
    inputemail(newEmail) {
      this.validationEmail(newEmail);
    },
    inputphone(newPhone) {
      this.validationPhone(newPhone);
    },
  },
  async created() {
       this.checkCookie();
       if (this.cookie != 0) {
      window.location.href = this.link;
    } else {
    try {
      const res = await axios.get(
        this.promoApiBaseUrl + `/landing-registration/countries`
      );
      this.countryObj = res.data.payload;
      this.arrOfObj1 = Object.values(this.countryObj);
      let obj = Object.keys(this.countryObj);
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] == this.countryCode) {
          let index = i;
          this.selectedCountry = this.countryCode;
          this.flagIconClass = "fi fi-" + this.selectedCountry.toLowerCase();
          this.countryCodePhone = "+" + this.arrOfObj1[index].phone_code[0];
          this.inputphone = this.countryCodePhone;
          this.value = this.arrOfObj1[index].name;
        }
      }
    } catch (error) {
      console.log(error);
    }
      window.addEventListener("beforeunload", this.leaving);
    }
  },
  methods: {
    async getCapchaKey() {
      try {
        const res = await axios.get(this.promoApiBaseUrl + `/recaptcha-key/v3`);

        this.siteKey = res.data.payload.key;
        this.addCaptchaScript();
      } catch (error) {
        console.log(error);
      }
    },
        setCookie() {
      var n = new Date();
      n.setFullYear(n.getFullYear() + 10),
        (document.cookie =
          "registered" +
          "=" +
          "true" +
          "; expires=" +
          n.setFullYear(n.getFullYear() + 10) +
          "; path=/;");
    },
    checkCookie() {
      var cookieArr = document.cookie.split(";");
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cookiePair[1] == "true") {
          this.cookie = 1;
        } else {
          this.cookie = 0;
        }
      }
    },
    addCaptchaScript() {
      if (!window.recaptchaAppended) {
        window.recaptchaAppended = !0;
        var t = document.createElement("script");
        (t.type = "text/javascript"),
          (t.src =
            "https://www.google.com/recaptcha/api.js?render=" +
            encodeURIComponent(this.siteKey)),
          (t.async = !0),
          (t.defer = !0),
          document.getElementsByTagName("head")[0].appendChild(t);
      }
    },
       validation() {
      this.isValid = false;
      if (this.currentStep === "one") {
        if (this.inputphone.length < 11) {
          this.isValid = false;
        } else {
          this.isValid = true;
        }
      } else if (this.currentStep === "two") {
        var re = /\S+@\S+\.\S+/;
        this.isValid = re.test(this.inputemail);
      }
    },
    async validationPhone(newPhone) {
      this.isValid = false;
      if (newPhone.length < 11) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
    },
    async validationEmail(newEmail) {
      this.isValid = false;
      var re = /\S+@\S+\.\S+/;
      this.isValid = re.test(newEmail);
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        this.errors.phone = null;
        return true;
      }
    },
    async recaptcha() {
      let grecaptcha = window.grecaptcha;
      var t = this;
      grecaptcha.ready(function () {
        grecaptcha
          .execute(t.siteKey, {
            action: "submit",
          })
          .then(function (token) {
            t.token = token;
            t.tokenIsLoaded = true;
          });
      });

      const fpPromise = FingerprintJS.load();

      // Get the visitor identifier when you need it.
      fpPromise
        .then((fp) => fp.get())
        .then((result) => {
          t.result = result;
        });

      this.wait();
    },
    wait() {
      if (this.tokenIsLoaded) {
        this.registrationHandler();
        this.tokenIsLoaded = false;
      } else {
        setTimeout(this.wait, 1000);
      }
    },

    loadComplete() {
      this.currencyArr.unshift(this.currencyCode);
      this.value = this.inputphone;
      this.currency = this.currencyCode;
    },
    changeCountry(value) {
      let obj = this.arrOfObj1;
      for (let i = 0; i < obj.length; i++) {
        if (obj[i].name == value) {
          let index = i;
          this.countryCode = Object.keys(this.countryObj)[i];
          this.countryCodePhone = "+" + this.arrOfObj1[index].phone_code[0];
          this.inputphone = this.countryCodePhone;
          this.value = this.arrOfObj1[index].name;
          this.flagIconClass =
            "fi fi-" + Object.keys(this.countryObj)[i].toLowerCase();
            console.log(this.countryCode)
        }
      }
    },
    triggerPromocode() {
      this.add_promocode = true;
    },
    registrationHandler() {
      Object.assign(this.registration_data, {
        site_id: window.serverData.siteId,
      });

      if (this.currentStep == "two") {
        Object.assign(this.registration_data, { email: this.inputemail });
      } else {
        let phoneW = this.inputphone.replace(this.countryCodePhone, "");
        Object.assign(this.registration_data, { phone: phoneW });
      }

      Object.assign(this.registration_data, { recaptcha_response: this.token });
      Object.assign(this.registration_data, { currency_code: this.currency });
      Object.assign(this.registration_data, { country_code: this.countryCode});
      Object.assign(this.registration_data, { btag: window.serverData.btag });
      Object.assign(this.registration_data, { promo_code: this.promocode1 });
      Object.assign(this.registration_data, { bonus_type: this.bonus_type });
      Object.assign(this.registration_data, {
        visitor_id: this.result.visitorId,
      });
      Object.assign(this.registration_data, { site_path: this.sitePath });
      const data = this.registration_data;
      const headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      let t = this;
      axios
        .post(
          this.promoApiBaseUrl + `/betwinner/user-registration/register`,
          data,
          { headers }
        )
      .then(function (response) {
           if (response.data.success == true) {
           t.currentStepForm = "four";
          t.responseData.login = response.data.payload.login;
          t.responseData.password = response.data.payload.password;
          t.responseData.autoLoginUrl = response.data.payload.autoLoginUrl;
          t.responseData.autoLoginHomepageUrl =
            response.data.payload.autoLoginHomepageUrl;
          t.setCookie();
          }
          else{
               t.currentStepForm = "two";
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.errors.email) {
            t.errors.email = error.response.data.errors.email[0].message;
            setTimeout(() => {
              t.errors.email = "";
            }, 2000);
          }
          if (error.response.data.errors.phone) {
            t.errors.phone = error.response.data.errors.phone[0].message;
            setTimeout(() => {
              t.errors.phone = "";
            }, 2000);
          }
        });

      this.registration_data = {};
    },
  },
  mounted() {
    this.loadComplete();
    this.getCapchaKey();
  },
  components: { ElButton, ElSelect, ElInput, ElOption },
  setup() {
    return {};
  },
};
</script>

<style>
.el-select-dropdown__item {
 font-family: "Montserrat", sans-serif;
}

.registration-layout {
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
   font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  position: relative;
  margin: 0 auto;
  color: #000;
  border-radius: 16px;
  position: relative;
  max-width: 312px;
  height: 100%;
}

.registration-layout-bonus-title {
    margin-bottom: 15px;
    margin-top: 15px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.registration-layout .has-account {
padding: 0;
    cursor: pointer;
 font-weight: 600;
font-size: 12px;
line-height: 24px;
    color: #fff!important;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    margin-top: 2px;
    width: 100%;
    text-align: center;
    justify-content: center;
    height: 24px;
}
.registration-layout .has-account .icon {
  padding-right: 6px;
}
.registration-layout-tabs {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 48px;
  margin-bottom: 8px;
}
.registration-layout-tabs-tab {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;

  font-weight: 600;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.bonus-tab .registration-layout-tabs-tab {
  font-size: 12px;
  line-height: 16px;
}

.tabs.registration-layout-tabs.top-tabs .icon,.registration-layout-tabs-tab .icon {
     padding: 0 4px 0 0px;
}
.registration-layout-tabs-tab.active {
  background-color: #FFCE06;
}
.registration-layout-tabs-tab:first-child {
  border-radius: 12px 0 0 12px;
}
.registration-layout-tabs-tab:last-child {
  border-radius: 0 12px 12px 0;
}
.tab-content .item {
  display: none;
}
.tab-content .item.active {
  display: flex;
}
.registration-layout .el-input {
  margin: 0;
  width: 100%;
  height: 48px;
  line-height: 48px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff;
  border: 0;
  outline: none;
  border-radius: 12px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.registration-layout .el-input.invalid {
  border: 1px solid red;
}
.registration-layout .el-button {

  background-color: #FFCE06;
  border-color: #FFCE06;
  color: #000;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  height: 48px;
  border-radius: 12px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  outline: none;
  margin: 0;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.bn .registration-layout .el-button  {
 font-family: 'Noto Sans Bengali', sans-serif;
 font-style: normal;
}
.registration-layout .el-button.btn-main-action.is-disabled,
.registration-layout .el-button.btn-main-action.is-loading {
  opacity: 0.65;
  background-color: #FFCE06;
  border-color: #FFCE06;
}
.registration-layout .el-input-group {
  padding: 8px;
  background-color: #fff;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 48px;
  line-height: 48px;
}

.registration-layout .el-input-group__prepend {
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 74%, 0.2);
  line-height: 15px;
  padding: 10px 16px;
  border: none;
  box-sizing: border-box;
  box-shadow: none;
}
.registration-layout-body-row {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
  max-width: 312px;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 8px;
}
.registration-layout-body-row-cell {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.country-cell {
  margin-right: 10px;
  flex: 4;
}
.registration-layout .el-input__wrapper {
  border-radius: 12px;
  box-shadow: none;
}
.currency-cell .el-input {
  width: 96px;
}
.currency-cell .el-input__inner {
  padding-left: 14px;
}
.registration-layout-body,
.registration-layout-body-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}
.registration-layout .licence-info {
color: rgba(92, 153, 133, 0.8);
font-weight: 500;
font-size: 8px;
line-height: 10px;
  text-align: center;
  margin: 0;
}
.registration-layout .licence-info  span{
  color: rgba(255, 255, 255, 0.8);
}
.tooltip {
  position: absolute;
  padding: 10px;
  left: 0;
  right: 0;
  border: 1px solid #e5495d;
  color: #ff455c;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%);
  z-index: 4;
  display: block;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  background: white;
  top: 105%;
}
.form-group {
  text-align: center;
  position: absolute;
width: 312px;
height: 312px;
left: calc(50% - 312px/2);
top: calc(50% - 312px/2 - 24px);
}
.reg-btn {
  position: absolute;
width: 312px;
height: 48px;
left: calc(50% - 312px/2);
bottom: 108px;
}
.has-accout-row{
  position: absolute;
width: 312px;
    height: 24px;
    left: calc(50% - 312px/2);
    bottom: 64px;
}
.form-final-text .licence-info{
    position: absolute;
width: 312px;
height: 36px;
left: calc(50% - 312px/2 - 2px);
    bottom: 10px;
}
.add-promocode {
  font-size: 12px;
  font-weight: 600;
  color: #FFCE06;
  cursor: pointer;
height: 24px;
  opacity: 1;
  background: 0 0;
  border: none;
  outline: none;
    padding: 2px;
    margin-bottom: 7px;
     margin-top: 18px;
}
i.icon:before {
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background-repeat: no-repeat;
  background-position: 50%;
}
i.phone:before {
  background-image: url(../assets/phone.svg);
}
i.email:before {
  background-image: url(../assets/email.svg);
}
i.sport:before {
  background-image: url(../assets/sport.svg);
}
i.casino:before {
  background-image: url(../assets/casino.svg);
}
i.cross:before {
  background-image: url(../assets/cross.svg);
}
i.enter:before {
  background-image: url(../assets/enter.svg);
}
.registration-layout .el-select__caret svg {
  display: none;
}
.registration-layout .el-select__caret:before {
  border: none;
  width: 10px;
  height: 8px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  transform: rotateX(180deg);
  z-index: 1;
  border-radius: 2px;
  background-image: url(../assets/arrow.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  content: "";
}
.el-select-dropdown__item.selected {
  color: black;
  font-weight: 700;
}
.is-disabled {
  opacity: 0.65;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: #FFCE06;
  border-color: #FFCE06;
  color: #000;
}
.registration-layout .el-input__inner {
  cursor: pointer;
  color: #000000;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: "Montserrat";
}
.reg-data-container-login{
  position: absolute;
  width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 - 60px);
}
.reg-data-container-pswd{
  position: absolute;
width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 + 60px);
}
.reg-data-container-link{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 8px;

position: absolute;
width: 264px;
height: 48px;
left: calc(50% - 264px/2);
bottom: 96px;
}
.reg-data-title{
font-family: 'Montserrat';
    font-style: italic;
    font-weight: 900;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}
.reg-data-description{
  font-family: 'Montserrat';
font-style: italic;
font-weight: 700;
font-size: 24px;
line-height: 32px;
display: flex;
align-items: center;
text-align: center;
    color: rgb(153 137 92 / 80%);
justify-content: center;
}
.reg-data-btn a{
  width: 264px;
height: 48px;
text-decoration: none;
}
.modal h2#form-title {
  text-align: center;
  position: absolute;
  width: 360px;
  height: 48px;
  left: calc(50% - 360px / 2 - 2px);
  top: calc(50% - 48px / 2 - 227px);

  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 22px;
  line-height: 24px;

  margin: 0;
  color: white;
}
@media screen and (max-width: 959px) {



  .reg-data-container-login{
  width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 - 60px);
}
  .reg-data-container-pswd{
width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 + 60px);
}
  .reg-data-container-link{
    top: auto;
width: 264px;
height: 48px;
left: calc(50% - 264px/2);
bottom: 96px;
}
}
@media screen and (max-width: 599px) {
  .form-group{
    width: 312px;
height: 312px;
left: calc(50% - 312px/2);
top: calc(50% - 312px/2 - 18px);

  }
 .modal h2#form-title {
  top: calc(50% - 48px / 2 - 230px);
  }
    .reg-btn {
width: 312px;
height: 48px;
    left: calc(50% - 312px/2 + 1px);
bottom: 88px;
top:auto;
}
.has-accout-row {
    width: 312px;
    height: 24px;
    left: calc(50% - 312px/2);
    bottom: 42px;
top:auto;
}
.form-final-text .licence-info {  
    bottom: 3px;
    top: auto;
    width: 312px;
    height: 36px;
    left: calc(50% - 312px/2);
}
.reg-data-container-login{
  width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 - 84px);
}
.reg-data-container-pswd{
width: 312px;
height: 72px;
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 + 12px);
}
.reg-data-container-link{
  width: 264px;
height: 48px;
left: calc(50% - 264px/2);
bottom: 64px;
}
}

@media screen and (max-width: 599px) and (max-height: 552px) {
  .form-group {
    width: 312px;
    height: 312px;
    left: calc(50% - 312px/2);
    top: calc(50% - 312px/2 - 16px);
}
    .reg-btn {
width: 312px;
height: 48px;
left: calc(50% - 312px/2 + 1px);
    bottom: 52px;
top:auto;
}

.has-accout-row {
    position: absolute;
    width: 312px;
    height: 24px;
    left: calc(50% - 312px/2);
  bottom: 10px;
top:auto;
}
.modal h2#form-title {
    top: calc(50% - 48px / 2 - 218px);
}
.form-final-text .licence-info {
    bottom: -28px;
    top: auto;
    width: 312px;
    height: 36px;
    left: calc(50% - 312px/2);
}
.reg-data-container-login{
left: calc(50% - 312px/2);
top: calc(50% - 72px/2 - 96px);
}
.reg-data-container-pswd{
left: calc(50% - 312px/2);
top: calc(50% - 72px/2);
}
.reg-data-container-link{
left: calc(50% - 264px/2);
bottom: 48px;

}
}
</style>
