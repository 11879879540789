<template>
  <div class="game-container" :class="['toggle_' + this.toggleChestCount]">
    <div
      class="chest-item"
      v-for="(chest, index) in chests"
      :key="index"
      @click="toggleChest(index)"
      :class="{ active: activeChest === index, toggle: toggledChest === index }"
    >
      {{ chest }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      chests: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      activeChest: null,
      toggledChest: null,
      toggleChestCount: 0,
      canToggled: !0,
    };
  },
  emits: ["chestToggle"],
  props: ["checkCount"],
  methods: {
    goToReg() {
      this.isRegistration = !0;
    },
    toggleChest(index) {
      if (this.canToggled == 1) {
        this.canToggled = !1;
        var t = this;
        this.activeChest = index;
        console.log("toggle chest number = " + (index + 1));
        setTimeout(function () {
          t.toggledChest = index;
        }, 1000);
        setTimeout(function () {
          t.toggleChestCount++;
          t.activeChest = !1;
          t.toggledChest = !1;
          t.canToggled = !0;
          t.$emit("chestToggle", t.toggleChestCount);
        }, 2000);
      }
    },
  },
};
</script>

<style>
.game-container {
  display: flex;
  position: absolute;
  width: 724px;
  height: 592px;
  left: calc(50% - 724px / 2);
    top: calc(50% - 592px/2 + 64px);

  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.game-container .chest-item {
  width: 228px;
  height: 184px;
  background-image: initial;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-image: url(../assets/chest.png);
  cursor: pointer;
  transition: 0.1s;
      text-indent: -990px;
    overflow: hidden;
}
.game-container .chest-item:hover {
  transition: 0.1s;
  transform: scale(1.02);
}
.chest-item.active {
  -webkit-animation: a 0.5s;
  animation: a 0.5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.toggle_0 .chest-item.active.toggle {
  -webkit-animation: none;
  animation: none;
  background-image: url(../assets/empty_chest.png);
}
.toggle_1 .chest-item.active.toggle,
.toggle_2 .chest-item.active.toggle {
  -webkit-animation: none;
  animation: none;
  background-image: url(../assets/win_chest.png);
}
.chest__item.animations {
  position: relative;
}


@media screen and (max-width: 959px) {
.game-container {
  width: 550px;
height: 454px;
left: calc(50% - 550px/2);
top: calc(50% - 454px/2 + 87px);
}
.game-container .chest-item {
width: 174px;
height: 142px;
}

}
@media screen and (max-width: 599px) {
.game-container {
width: 340px;
height: 280px;
left: calc(50% - 340px/2);
top: 272px;
}
.game-container .chest-item {
width: 108px;
height: 88px;
}
}
@media screen and (max-width: 599px) and (max-height: 552px) {
.game-container {
  width: 340px;
height: 280px;
left: calc(50% - 340px/2);
top: 224px;
}


}
@-webkit-keyframes a {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px) rotate(1deg);
    transform: translate(-3px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
    transform: translate(1px, 2px) rotate(0deg);
  }

  to {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes a {
  0% {
    -webkit-transform: translate(1px, 1px) rotate(0deg);
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    -webkit-transform: translate(-3px) rotate(1deg);
    transform: translate(-3px) rotate(1deg);
  }

  30% {
    -webkit-transform: translate(3px, 2px) rotate(0deg);
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    -webkit-transform: translate(1px, -1px) rotate(1deg);
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    -webkit-transform: translate(-3px, 1px) rotate(0deg);
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    -webkit-transform: translate(3px, 1px) rotate(-1deg);
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    -webkit-transform: translate(-1px, -1px) rotate(1deg);
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    -webkit-transform: translate(1px, 2px) rotate(0deg);
    transform: translate(1px, 2px) rotate(0deg);
  }

  to {
    -webkit-transform: translate(1px, -2px) rotate(-1deg);
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
