<template>
  <div>
    <div class="modal-bg" :class="{ show: popupIsOpen == !0 }" id="modal1">
      <div class="modal">
        <div class="modal-border"></div>
        <div class="modal-bg1"></div>
        <div class="modal-wrapper" v-show="this.checkCount == 2">
          <h2 id="modal-title" v-html="$t('bonus_header_lose')"></h2>
          <div class="bonuses one_bonus">
            <div class="bonus1">0</div>
          </div>
          <div class="bonus-text">{{ $t("bonus_text_lose") }}</div>
          <button class="btn-one-more" @click="closeModal()">
            {{ $t("bonus_btn_lose") }}
          </button>
        </div>
        <div class="modal-wrapper" v-show="this.checkCount == 1">
          <h2 id="modal-title" v-html="$t('bonus_header_win')"></h2>
          <div class="bonuses one_bonus">
            <div class="bonus1">100%</div>
          </div>
          <div class="bonus-text">{{ $t("bonus_text_lose") }}</div>
          <button class="btn-one-more" @click="closeModal()">
            {{ $t("bonus_btn_lose") }}
          </button>
        </div>
        <div
          class="modal-wrapper last-bonus"
          v-show="this.checkCount == 0 && this.isRegistration == !1"
        >
          <h2 id="modal-title" v-html="$t('bonus_header_win')"></h2>
          <div class="bonuses two_bonus">
            <div class="bonus1">100%</div>
            <div class="bonus1">150fs</div>
          </div>
          <div class="bonus-text" v-html="$t('bonus_text_win')"></div>
          <button class="btn-one-more btn-reg" @click="startReg()">
            {{ $t("bonus_btn_win") }}
          </button>
          <div class="modal-has-account" style="text-align: center">
            <a
              :href="link"
              target="_blank"
              rel="noopener noreferrer"
              class="has-account"
            >
              <i class="icon enter"> </i>{{ $t("has_account") }}</a
            >
          </div>
        </div>
        <div class="modal-wrapper" v-show="this.isRegistration == !0">
          <AppForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppForm from "./AppForm.vue";

export default {
  data() {
    return {
      isRegistration: !1,
      currentStepModal: 1,
      link: window.serverData.clickUrl,
    };
  },
  emits: ["closePopup"],
  components: {
    AppForm,
  },
  props: ["popupIsOpen", "checkCount"],
  created() {
    // props are exposed on `this`
    console.log(this.checkCount);
  },
  methods: {
    closeModal() {
      let popupIsOpen1 = !1;
      this.currentStepModal++;
      this.$emit("closePopup", popupIsOpen1);
    },
    startReg() {
      this.isRegistration = !0;
    },
  },
};
</script>

<style>
.modal {
  padding: 20px;
  z-index: 999;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  width: 428px;
  height: 648px;
  position: relative;
  display: flex;
  color: white;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  top: calc(50% - 648px / 2);
  box-sizing: border-box;
}
.modal::before {
  content: "";
  position: absolute;
  width: 428px;
  height: 648px;
  left: calc(50% - 428px / 2);
  top: calc(50% - 648px / 2);
  background: radial-gradient(
    117.07% 52.03% at 50% 50%,
    rgba(19, 112, 78, 0.9) 0%,
    rgba(14, 64, 45, 0.9) 100%
  );
  border: 3px solid #ffce06;
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  border-radius: 15px;
}

.modal-bg {
  display: none;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  opacity: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  transition: all 0.5s ease-out;
}
.modal-bg.show {
  display: flex;
  opacity: 1;
}
.modal > div.modal-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
}
.modal h2#modal-title {
  text-align: center;
  position: absolute;
  width: 360px;
  height: 72px;
  left: calc(50% - 360px / 2);
  top: calc(50% - 72px / 2 - 148px);

  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-size: 28px;
  line-height: 32px;

  margin: 0;
  color: white;
}

.modal h2#modal-title span,
.modal h2#form-title span {
  color: #ffce06;
}
.modal-text {
  position: absolute;
  width: 360px;
  height: 64px;
  left: calc(50% - 360px / 2 - 10px);
  top: calc(50% - 64px / 2 + 88px);
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  /* green-light */

  color: #5c9999;
}
.btn-one-more {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  font-family: "Montserrat";
  position: absolute;
  width: 264px;
  height: 48px;
  left: calc(50% - 264px / 2);
  bottom: 28px;
}
.btn-one-more.btn-reg {
  bottom: 62px;
}
.btn-one-more {
  color: #002020;
  background: #ffce06;
  border-radius: 12px;
  display: flex;
  border: 0px solid transparent;
  cursor: pointer;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-style: italic;
  font-weight: 900;
  font-size: 16px;
  line-height: 16px;
}
.bonuses {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  align-content: center;
  position: absolute;
  width: 312px;
  height: 68px;
  left: calc(50% - 312px / 2);
  top: calc(50% - 106px / 2);
}
.bonuses.one_bonus {
  justify-content: center;
}
.bonuses.two_bonus {
  justify-content: space-between;
}
.bonus2,
.bonus1 {
  width: 144px;
  height: 68px;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffce06;
  text-shadow: 0px 0px 4px rgb(255 255 0 / 60%);
  border: 3px solid #ffce06;
  box-shadow: 0px 0px 9px #ffce06;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  box-sizing: border-box;
}
.modal-has-account a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
  font-size: 12px;
  line-height: 15px;
  text-decoration: none;
  color: white;
  position: absolute;
  width: 312px;
  height: 24px;
  left: calc(50% - 312px / 2 - 10px);
  top: calc(50% - 24px / 2 + 276px);
}
.modal-has-account .icon{
  padding-left: 16px;
    padding-right: 4px;
}
.bonus-text {
  position: absolute;
  width: 312px;
  height: 64px;
  left: calc(50% - 312px / 2);
  top: calc(50% - 64px / 2 + 72px);
  text-align: center;
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c9985;
  justify-content: center;
}
.modal .last-bonus h2#modal-title {
  top: calc(50% - 72px / 2 - 160px);
}

.last-bonus .bonuses {
  top: calc(50% - 126px / 2);
}
.last-bonus .bonus-text {
  top: calc(50% - 64px / 2 + 60px);
}
@-webkit-keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: calc(50% - 648px / 2 + 0.5px);
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: calc(50% - 648px / 2 + 0.5px);
    opacity: 1;
  }
}
@-webkit-keyframes slideIn1 {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: calc(50% - 276px);
    opacity: 1;
  }
}

@keyframes slideIn1 {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: calc(50% - 276px);
    opacity: 1;
  }
}
@media screen and (max-width: 959px) {
}
@media screen and (max-width: 599px) {
  .modal {
    width: 100%;
    height: 100%;
    top: 0;
  }

  .modal::before {
    background: radial-gradient(
      117.07% 52.03% at 50% 50%,
      rgba(19, 112, 78, 1) 0%,
      rgba(14, 64, 45, 1) 100%
    );
    border-left: 0px solid;
    border-right: 0px solid;
    backdrop-filter: blur(5px);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: -1;
  }
  .bonuses {
    top: calc(50% - 80px / 2);
  }
  .bonus2,
  .bonus1 {
    width: 120px;
    height: 61px;
    font-weight: 800;
    font-size: 24px;
    line-height: 24px;
  }
  .bonus-text {
    top: calc(50% - 64px / 2 + 86px);
  }
  #modal2 .modal {
    padding: 50px 24px;
    width: 499px;
    height: 596px;
    top: calc(50% - 298px);
  }

  .modal-text {
    width: 264px;
    height: 64px;
    left: calc(50% - 264px / 2);
    top: calc(50% - 64px / 2 + 85px);
  }
  .modal h2#modal-title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    width: 312px;
    height: 48px;
    left: calc(50% - 312px / 2);
    top: 168px;
  }
  .btn-one-more {
    bottom: 44px;
  }
  .bonuses {
    width: 264px;
    left: calc(50% - 264px / 2);
  }

  .modal .last-bonus h2#modal-title {
    top: calc(50% - 72px / 2 - 96px);
  }

  .last-bonus .bonuses {
top: calc(50% - 70px/2);
  }
  .last-bonus .bonus-text {
    width: 264px;
    left: calc(50% - 264px / 2);
    top: calc(50% - 64px / 2 + 87px);
  }
  .btn-one-more.btn-reg {
    bottom: 52px;
}
.modal-has-account a{
  top: auto;
      bottom: 16px;
}
  @-webkit-keyframes slideIn {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
}
@media screen and (max-width: 599px) and (max-height: 552px) {
  .modal::before {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: -1;
    border: 0px solid;
  }
  .modal-border::before,
  .modal-border::after {
    display: none;
  }
  .modal h2#modal-title {
    width: 360px;
    height: 48px;
    left: calc(50% - 360px / 2);
    top: 122px;
  }
  .bonuses {
    top: calc(50% - 134px / 2);
  }
  .bonus2,
  .bonus1 {
    width: 120px;
    height: 56px;
  }
  .bonus-text {
    top: calc(50% - 64px / 2 + 52px);
  }
  .btn-one-more {
    bottom: 27px;
  }
  .modal .last-bonus h2#modal-title {
    top: calc(50% - 72px / 2 - 99px);
}
  .last-bonus .bonuses {
    top: calc(50% - 131px/2);
}
.last-bonus .bonus-text {
    top: calc(50% - 64px / 2 + 50px);
}
.modal-has-account a {
   top: auto;
      bottom: 16px;
}
}
</style>
