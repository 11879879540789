<template>
  <div class="wrapper">
    <div class="bg"></div>
    <AppHeader :check-count="checkCount" :prize-count="prizeCount"/>

    <AppGame :check-count="checkCount" @chestToggle="chestToggleEv($event)"/>
    <AppPopup :popup-is-open="popupIsOpen"  @closePopup="closePopupEv($event)" :check-count="checkCount"/>
  </div>
</template>

<script>
import AppGame from "./AppGame.vue";
import AppPopup from "./AppPopup.vue";
import AppHeader from "./AppHeader.vue";
export default {
  data() {
    return {
      popupIsOpen: !1,
      checkCount:3,
      prizeCount:0,
    };
  },

  methods: {
    chestToggleEv() {
      this.checkCount = this.checkCount - 1;
      if (this.checkCount == 2) {
        this.prizeCount=0;
      }
      else {
        this.prizeCount++;
      }
        this.popupIsOpen=!0;
      console.log(this.checkCount);
    },
     closePopupEv(e) {
      this.popupIsOpen = e;
    },
  },
  components: {
    AppGame,
    AppPopup,
    AppHeader,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,800;0,900;1,500;1,600;1,800;1,900&display=swap");
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input[type="text"] {
  font-size: inherit;
}
body,
html {
  overflow: hidden;
  scroll-behavior: smooth;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
#app {
  height: 100%;
  position: relative;
}
body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  padding: 0;
  margin: 0;
  background-color: #004931;
  background: linear-gradient(180deg, #004931 0%, #003322 100%);
}
.wrapper {
  position: relative;
  color: #fff;
  height: 100%;
  padding: 48px 0 0;
  box-sizing: border-box;
}

.wrapper.en {
  font-family: "Montserrat", sans-serif;
}

.bg {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-image: url(../assets/back1.jpg);
}


.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 864px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
br.desctop {
  display: block;
}
br.mobile,
br.tablet {
  display: none;
}
@media screen and (max-width: 959px) {
  .container {
    max-width: 560px;
  }

  .bg {
    background-image: url(../assets/back_1.jpg);
  }
  br.tablet {
    display: block;
  }
  br.mobile,
  br.desctop {
    display: none;
  }
}
@media screen and (max-width: 599px) {
  .wrapper {
    padding: 31px 0 0;
  }

  .container {
    max-width: 312px;
  }
  .container.fluid-container {
    max-width: 100%;
    position: static;
  }
  .header .logo {
width: 169.05px;
height: 16px;
left: calc(50% - 169.05px/2 - 0.48px);
top: 24px;

  }
  br.mobile {
    display: block!important;
  }
  br.tablet,
  br.desctop {
    display: none;
  }

  .bg {
    background-image: url(../assets/back_2.jpg);
  }
}

@media screen and (max-width: 599px) and (max-height: 552px) {
  .wrapper {
    padding: 31px 0 0;
  }

  .container {
    max-width: 312px;
  }
  .container.fluid-container {
    max-width: 100%;
    position: static;
  }

  .bg {
    background-image: url(../assets/back_2_1.jpg);
  }
}
@media screen and (max-width: 599px) and (min-height: 552px) {
}
@media screen and (max-width: 359px) {
}
</style>
