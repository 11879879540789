import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import App from "./App.vue";
import FlagIcons from "flag-icons";

const messages = {
  en: {
    by_phone: "By phone",
    by_email: "Via email",
    email: "E-Mail",
    phone: "Phone",
    enter_email: "Enter E-Mail",
    login: "Login",
    password: "Password",
    final_text:
      "By clicking the <span>«Signup»</span> button, you agree to the terms of the bonus promotion and the rules of the bookmaker.",
    registration: "signup",
    make_deposit: "Make a deposit",
    promocode: "Promocode",
    has_account: "Already have an account",
    add_promocode: "Add promocode",
    bonus_sport: "Sport",
    bonus_casino: "Casino",
    bonus_decline: "Refuse",
    bonus_title: "Choose your bonus",

    btn_text: "Play now",
    header_site1: "<span> Bonus €1500 + 150 FS </span> <br/>for new clients",
    header_site2: "<span> Bonus €1500 + 150 FS </span> <br/>for new clients",
    final_text1:
      "By clicking the 'Play now' button, you agree to the terms of the bonus promotion and the rules of the bookmaker.",
    count_header1: "select chest",
    count_header2: "GET PRIZES",
    count_text1: "NUMBER of actions: ",
    count_text2: "NUMBER OF PRIZES:",
    bonus_text_win:
      "100% BONUS + 150 FREE SPINS<br/> Register and collect bonuses",
    bonus_text_lose: "Try again",
    bonus_header_win: "Congratulations, <span>you<br/> win!</span>",
    bonus_header_lose: "You didn't win anything",
    bonus_btn_lose: "Continue",
    bonus_btn_win: "registration",
  },
  ru: {
    by_phone: "По телефону",
    by_email: "По E-Mail",
    phone: "Телефон",
    email: "E-Mail",
    enter_email: "Введите E-Mail",
    login: "Логин",
    password: "Пароль",
    final_text:
      "Нажимая кнопку «Зарегистрироваться», вы соглашаетесь с условиями бонусной акции и правилами букмекерской конторы.",
    registration: "Зарегистрироваться",
    make_deposit: "Сделать депозит",
    promocode: "Промокод",
    has_account: "У меня уже есть аккаунт",
    add_promocode: "Добавить промокод",
    bonus_sport: "Спорт",
    bonus_casino: "Казино",
    bonus_decline: "Отклонить",
    bonus_title: "Выберите ваш бонус",

    btn_text: "Играть сейчас",
    header_site1: "<span> Бонус €1500 + 150 FS </span> для новых клиентов",
    header_site2: "<span> Бонус €1500 + 150 FS </span> <br/>для новых клиентов",
    final_text1:
      "Нажимая кнопку «Играть сейчас», вы соглашаетесь с условиями бонусной акции и правилами букмекерской конторы.",
    count_header1: "выберите сундук",
    count_header2: "ПОЛУЧАЙ ПРИЗЫ",
    count_text1: "КОЛИЧЕСТВО действий: ",
    count_text2: "КОЛИЧЕСТВО ПРИЗОВ: ",
    bonus_text_win:
      "100% BONUS + 150 FREE SPINS<br/> Регистрируйся и забирай бонусы",
    bonus_text_lose: "Попробуйте еще раз",
    bonus_header_win: "Поздравляем, <span>вы<br/>  выиграли!</span>",
    bonus_header_lose: "Вы ничего, <span>не<br/>  выиграли</span>",
    bonus_btn_lose: "продолжить",
    bonus_btn_win: "регистрация",
  },
  es: {
    by_phone: "Por el teléfono",
    by_email: "Por el E-Mail",
    email: "E-Mail",
    phone: "Teléfono",
    enter_email: "Introduce el E-Mail",
    login: "Usuario",
    password: "Contraseña",
    final_text:
      "Al pulsar el botón 'Registrarse', aceptas las condiciones de la oferta y las reglas de la casa de apuestas.",
    registration: "Registrarse",
    make_deposit: "Hacer el depósito",
    promocode: "El código promocional",
    has_account: "Ya tengo una cuenta",
    add_promocode: "Agregar el código promocional",
    bonus_sport: "Deporte",
    bonus_casino: "Casino",
    bonus_decline: "Rechazar",
    bonus_title: "Elija su bono",

    btn_text: "Играть сейчас",
    header_site1:
      "<span> Bono 1500 € + 150 FS </span> <br/>para nuevos clientes",
    header_site2:
      "<span> Bono 1500 € + 150 FS </span> <br/>para nuevos clientes",
    final_text1:
      "Al pulsar el botón 'Registrarse', aceptas las condiciones de la oferta y las reglas de la casa de apuestas.",
    count_header1: "ELIJA UN BAÚL",
    count_header2: "OBTENGA PREMIOS",
    count_text1: "CANTIDAD DE ACCIONES:",
    count_text2: "CANTIDAD DE PREMIOS: ",
    bonus_text_win:
      "100 % DE BONO + 150 GIROS GRATIS<br/> Regístrese y obtenga bonos",
    bonus_text_lose: "Intentar otra vez",
    bonus_header_win: "¡Felicitaciones, <span>tú<br/> ganaste!</span>",
    bonus_header_lose: "No ganaste nada",
    bonus_btn_lose: "Continuar",
    bonus_btn_win: "Registrarse",
  },
  pt: {
    by_phone: "Por telefone",
    by_email: "Por E-Mail",
    email: "E-Mail",
    phone: "Telefone",
    enter_email: "Insira E-Mail",
    login: "Login",
    password: "Palavra-passe",
    final_text:
      "Ao clicar no botão 'Registrar' você concorda com os termos da promoção de bônus e com as regras da Betwinner",
    registration: "Registrar",
    make_deposit: "Fazer um depósito",
    promocode: "Código promocional",
    has_account: "Você já possui um conta",
    add_promocode: "Adicionar código promocional",
    bonus_sport: "Desporto",
    bonus_casino: "Cassino",
    bonus_decline: "Recusar",
    bonus_title: "Escolha o seu bônus",

    btn_text: "Играть сейчас",
    header_site1: "<span> €1500 + 150 FS Bónus </span><br/> para novos clientes",
    header_site2: "<span> €1500 + 150 FS Bónus </span><br/>  para novos clientes",
    final_text1:
      "Ao clicar no botão 'Registrar' você concorda com os termos da promoção de bônus e com as regras da Betwinner",
    count_header1: "ESCOLHA UM BAÚ",
    count_header2: "RECEBA PRÊMIOS",
    count_text1: "QUANTIDADE DE AÇÕES: ",
    count_text2: "QUANTIDADE DE PRÊMIOS: ",
    bonus_text_win:
      "BÔNUS DE 100% + 150 RODADAS GRÁTIS<br/> Registre-se e colete bônus",
    bonus_text_lose: "Tente novamente",
    bonus_header_win: "Parabéns, <span>você<br/> venceu!</span>",
    bonus_header_lose: "Você não vai ganhar nada, <span><br/></span>",
    bonus_btn_lose: "continuar",
    bonus_btn_win: "Registrar",
  },
  uz: {
    by_phone: "Telefon orqali",
    by_email: "E-Mail orqali",
    phone: "Telefon",
    email: "E-Mail",
    enter_email: "E-Mail ni kiriting",
    login: "Login",
    password: "Parol",
    final_text:
      "Ro'yxatdan o'tish tugmasini bosish orqali siz bonusli aktsiya shartlari va bukmekerlar qoidalariga rozilik bildirasiz.",
    registration: "Ro'yxatdan o'tish",
    make_deposit: "Depozit qilish",
    promocode: "Promokod",
    has_account: "O'yin hisob mavjud",
    add_promocode: "Promokod qo'shish",
    bonus_sport: "Sport",
    bonus_casino: "Kazino",
    bonus_decline: "Rad etish",
    bonus_title: "BONUS tanlang",

    btn_text: "Hozir O'yna",
    header_site1: "Ro'yxatga olish <br/> <span> €1500 + 150 FS </span> ",
    header_site2: "Ro'yxatga olish <br/> <span> €1500 + 150 FS </span> ",
    final_text1:
    "Ro'yxatdan o'tish tugmasini bosish orqali siz bonusli aktsiya shartlari va bukmekerlar qoidalariga rozilik bildirasiz.",
    count_header1: "KO'KRAKNI TANLANG",
    count_header2: "SOVRINLAR",
    count_text1: "URINISHLAR SONI: ",
    count_text2: "SOVRINLAR SONI: ",
    bonus_text_win:
      "100% BONUS + 150 FREE SPINS<br/> Регистрируйся и забирай бонусы",
    bonus_text_lose: "QAYTA URINIB KO'RING",
    bonus_header_win: "Tabriklaymiz, <span>siz<br/>yutdingiz!</span>",
    bonus_header_lose: "Siz hech narsa yutmadingiz",
    bonus_btn_lose: "DAVOM ETING",
    bonus_btn_win: "BONUS OLING",
  },
  kk: {
    by_phone: "Телефон арқылы",
    by_email: "E-Mail арқылы",
    phone: "Телефон",
    email: "E-Mail",
    enter_email: "E-Mail енгізіңіз",
    login: "Логин",
    password: "Құпиясөз",
    final_text: "«Тіркелу» батырмасын бау арқылы сіз бонустық акция талаптарымен және букмекерлік кеңсенің ережелерімен келісесіз.",
    registration: "Тіркелу",
    make_deposit: "Салым салу",
    promocode: "Промокод",
    has_account: "Аккаунт бұрыннан бар",
    add_promocode: "Промокод қосу",
    bonus_sport: "Спорт",
    bonus_casino: "Казино",
    bonus_decline: "Бас тарту",
    bonus_title: "Өз бонусыңызды таңдаңыз",

    btn_text: "Играть сейчас",
    header_site1: "Жаңа клиенттер үшін <span> €1500 + 150 FS бонусы </span>",
    header_site2: "Жаңа клиенттер үшін <span> €1500 + 150 FS бонусы </span>",
    final_text1: "«Тіркелу» батырмасын бау арқылы сіз бонустық акция талаптарымен және букмекерлік кеңсенің ережелерімен келісесіз.",
    count_header1: "КЕУДЕНІ ТАҢДАҢЫЗ",
    count_header2: "СЫЙЛЫҚТАР",
    count_text1: "ӘРЕКЕТ САНЫ:  ",
    count_text2: "ЖҮЛДЕЛЕР САНЫ: ",
    bonus_text_win:
      "100% БОНУС + 150 ТЕГІН АЙНАЛДЫРУ<br/> Тіркеліп, бонустарды жинаңыз",
    bonus_text_lose: "Тағы да қайталап көріңіз",
    bonus_header_win: "Құттықтаймыз, <span>сіз<br/> жеңдіңіз!</span>",
    bonus_header_lose: "Сіз ештеңе ұтпайсыз, <span><br/></span>",
    bonus_btn_lose: "Жалғастыру",
    bonus_btn_win: "Тіркелу",
  },
};
function detectLang() {
  let lang;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has("lang")) {
    lang = urlParams.get("lang");
  } else {
    let b = window.serverData.visitorData.languageCode;
    switch (b) {
      case "en":
        lang = "en";
        break;
      case "pt":
        lang = "pt";
        break;
      case "es":
        lang = "es";
        break;
      case "ru":
        lang = "ru";
        break;
      case "uz":
        lang = "uz";
        break;
      case "kk":
        lang = "kk";
        break;
      default:
        lang = "en";
    }
  }
  return lang;
}

const i18n = createI18n({
  locale: detectLang(), 
  fallbackLocale: "en", 
  allowComposition: true,
  messages,
});
let app = createApp(App);
app.use(FlagIcons);
app.use(i18n);
app.mount("#app");
